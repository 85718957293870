import { illustrationsSet } from "@/core/helpers/config";

export const getIllustrationsPath = (illustrationName: string): string => {
  return `media/illustrations/${illustrationsSet.value}/${illustrationName}`;
};

export const getLogoPath = () => {
  return `media/logos/logo.png`;
}
export const getLogoColorPath = () => {
  return `media/logos/Logo_color.png`;
}
export const getLogoAsidePath = () => {
  return `media/logos/Logo-bn.png`;
}
export const getLogoLightPath = () => {
  return `media/logos/logo.png`;
}

export const getBackGroundLoginPath = () => {
  return "media/backgrounds/PULMERO_BG1.png"
}